import React, { useEffect, useState, useRef } from 'react';
import axios from "axios";
import { BsPrinterFill, BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { NavLink, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import pic from "../pages/digitallogo.png";
import "./payslip.css";

export default function Visit() {

  const [employeeList, setEmployeeList] = useState([]);
  const { id } = useParams();

  const onVisitData = () => {
    let apiURL = "https://68.178.148.82:8080/viewing/" + id;
    axios.get(apiURL).then((response) => {
      // console.log(response.data)
      setEmployeeList(response.data[0])
    })
  }

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  useEffect(() => {
    onVisitData();
  }, []);

  return (
    <div>
      <NavLink to="/paysliplist">
        <button className='icons mx-2'><BsFillArrowLeftCircleFill size={25} /></button>
      </NavLink>
      <button className="icons mx-2" onClick={handlePrint}><BsPrinterFill size={25} /></button>

      <div ref={componentRef} className="font spacing">

        <div className="d-flex ">

          <div style={{ width: "50%" }}>
            <img src={pic} alt="Logo" className='logo' />
          </div>
          <div>
            <div className="vls"></div>
          </div>
          <div>
            <div id="DtHeading">Digital Triumph Private Limited</div>
            <div style={{ fontSize: "15px" }}> AC 149,Plot No 4920,AC Block,<br />
              Achu Towers, 3rd Floor, 2nd Avenue<br />
              Anna Nagar, Chennai - 600040.</div>
          </div>
        </div>

        <hr className="HorizontalLineDiv" style={{ marginTop: "0px" }} />

        <div className='pb-3'>
          <label>Pay Slip for the month of   </label>   {new Date(employeeList.month).toLocaleDateString('en-us', { year: "numeric", month: "long" })}
        </div>

        <table className="table table-bordered table-sm tables" >
          <tbody className='ths'>
            <tr>
              <th>Employee Name</th>
              <td>{employeeList.name}</td>
              <th>Total Working Days</th>
              <td>{employeeList.workingDays}</td>
            </tr>
            <tr>
              <th>Employee ID</th>
              <td>{employeeList.employeeId}</td>
              <th>LOP Days</th>
              <td>{employeeList.lopDays}</td>
            </tr>
            <tr>
              <th>Designation</th>
              <td>{employeeList.designation}</td>
              <th>Bank Name</th>
              <td>{employeeList.bankName}</td>
            </tr>
            <tr>
              <th>Department</th>
              <td>{employeeList.department}</td>
              <th>Bank A/c No.</th>
              <td>{employeeList.bankAccountNumber}</td>
            </tr>
            <tr>
              <th>Date of Joining</th>
              <td>{new Date(employeeList.dateOfJoining).toLocaleDateString('en-GB')}</td>
              <th>PAN</th>
              <td>{employeeList.panNumber}</td>
            </tr>
            <tr>
              <th>UAN</th>
              <td>{employeeList.uanNumber}</td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <table className="table table-bordered table-sm tables" >
          <tbody>
            <tr>
              <th colSpan="2" className='text-center'><h3>Earnings</h3></th>
              <th colSpan="2" className='text-center'><h3>Deductions</h3></th>
            </tr>
            <tr>
              <th>Basic Salary</th>
              <td>{employeeList.salary}</td>
              <th>PF</th>
              <td>{employeeList.pf}</td>
            </tr>
            <tr>
              <th>House Rent Allowances</th>
              <td>{employeeList.houseRentAllowance}</td>
              <th>Income Tax</th>
              <td>{employeeList.incomeTax}</td>
            </tr>
            <tr>
              <th>Conveyance Allowances</th>
              <td>{employeeList.conveyanceAllowance}</td>
              <th>Professional Tax</th>
              <td>{employeeList.professionalTax}</td>
            </tr>
            <tr>
              <th>Medical Allowances</th>
              <td>{employeeList.medicalAllowance}</td>
              <th>LOP Amount</th>
              <td>{employeeList.lopAmount}</td>
            </tr>
            <tr>
              <th>Special Allowances</th>
              <td>{employeeList.specialAllowance}</td>
              <td></td>
              <td></td>
            </tr>
            <tr>
              <th>Gross Salary</th>
              <td>{employeeList.grossSalary}</td>
              <th>Total Deductions</th>
              <td>{employeeList.totalDeductions}</td>
            </tr>
            <tr>
              <th colSpan="2" className='text-center ths'>Net Salary</th>
              <td colSpan="2" className='tds'>{employeeList.netSalary}</td>
            </tr>
            <tr>
              <th colSpan="2" className='text-center ths'>Amount in Words</th>
              <td colSpan="2" className='tds'>{employeeList.amountinwords}</td>
            </tr>
          </tbody>
        </table>
        
        <h5 style={{ paddingTop: "8px" }}>Employee Signature :</h5>
        <p className='footer'>This is an autogenerated document. It does not need any official signature.</p>
      </div>
    </div>
  )
}
