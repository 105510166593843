import React, { useState, useRef } from 'react';
import pic from "../pages/digitallogo.png";
import axios from "axios";
import { NavLink } from 'react-router-dom';
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';
import { ToWords } from 'to-words';
import { useReactToPrint } from "react-to-print";
import "./payslip.css";

function Payslip() {

    const handleClick = () => {
        localStorage.clear();
        window.location.replace("http://localhost:3000/");
    }

    const [employeeId, setEmployeeId] = useState("");
    const [designation, setDesignation] = useState("");
    const [salary, setSalary] = useState(0);
    const [houseRentAllowance, setHouse] = useState(0);
    const [conveyanceAllowance, setConvayance] = useState(0);
    const [medicalAllowance, setMedical] = useState(0);
    const [specialAllowance, setSpecial] = useState(0);
    const [name, setName] = useState();
    const [department, setDepartment] = useState();
    const [incomeTax, setIncome] = useState(0);
    const [month, setMonth] = useState(0);
    const [workingDays, setWorkingDays] = useState(30);
    const [lopDays, setLopDays] = useState(0);
    const [dateOfJoining, setDateOfJoining] = useState(0);
    const [bankName, setBankName] = useState();
    const [bankAccountNumber, setBankAccountNumber] = useState(0);
    const [panNumber, setPanNumber] = useState(0);
    const [uanNumber, setUanNumber] = useState(0);

    const grossSalary = parseInt(salary) + parseInt(houseRentAllowance) + parseInt(conveyanceAllowance) + parseInt(medicalAllowance) + parseInt(specialAllowance) || "";
    const pf = salary * 0.12 || "";
    const professionalTax = 210;
    const lopAmount = lopDays * (salary / workingDays) || 0;
    const totalDeductions = parseInt(pf) + parseInt(incomeTax) + parseInt(professionalTax) + parseInt(lopAmount) || "";
    const netSalary = grossSalary - totalDeductions || "";
    const toWords = new ToWords();
    const amountinwords = toWords.convert(Number(netSalary), { currency: true });

    const [employeeList, setEmployeeList] = useState([]);
    // posting data to backend

    // "http://payslip.digitaltriumph.in/create"
    const onSubmitForm = () => {
        let apiURL = "https://68.178.148.82:8080/createpayslip";
        axios.post(apiURL, {
            salary: salary,
            houseRentAllowance: houseRentAllowance,
            conveyanceAllowance: conveyanceAllowance,
            medicalAllowance: medicalAllowance,
            specialAllowance: specialAllowance,
            grossSalary: grossSalary,
            pf: pf,
            incomeTax: incomeTax,
            professionalTax: professionalTax,
            workingDays: workingDays,
            lopAmount: lopAmount,
            lopDays: lopDays,
            totalDeductions: totalDeductions,
            netSalary: netSalary,
            name: name,
            department: department,
            month: month,
            bankAccountNumber: bankAccountNumber,
            bankName: bankName,
            panNumber: panNumber,
            dateOfJoining: dateOfJoining,
            employeeId: employeeId,
            designation: designation,
            uanNumber: uanNumber,
            amountinwords: amountinwords,
        }).then(() => {
            setEmployeeList([...employeeList])
        })
        alert("Payslip has been submited")
    }
    const componentRef = useRef();
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <div>
            <div className='d-flex justify-content-between p-2'>
                <NavLink to="/paysliplist">
                    <button className='icons'><BsFillArrowLeftCircleFill size={25} /></button>
                </NavLink>
                <button onClick={handleClick} style={{ border: "none", background: "none", color: "#f66700" }} className="fs-4">Logout</button>
            </div>
            <div className="container card-0 justify-content-center body">
                <div className="card-body px-sm-4 px-0">
                    <div className="row justify-content-center mb-5">
                        <div className="col-md-10 col">
                            <p className="mt-md-4 ml-md-0 ml-2 text-center text-sm-left"></p>
                        </div>
                    </div>
                    <div className="row justify-content-center round">
                        <div className="col-lg-10 col-md-12 " style={{ width: "95%" }}>
                            <div className="card shadow-lg card-1">
                                <div className="card-body inner-card" >
                                    <div>
                                        {/* header */}
                                        <div className="d-flex">
                                            <div style={{ width: "53%" }}>
                                                <img src={pic} alt="Logo" className='logo' />
                                            </div>
                                            <div>
                                                <div className="vls"></div>
                                            </div>
                                            <div>
                                                <div id="DtHeading">Digital Triumph Private Limited</div>
                                                <div style={{ fontSize: "15px" }}> AC 149,Plot No 4920,AC Block,<br />
                                                    Achu Towers, 3rd Floor, 2nd Avenue<br />
                                                    Anna Nagar, Chennai - 600040.</div>
                                            </div>
                                        </div>
                                        <hr className="HorizontalLineDiv" style={{ marginTop: "0px" }} />
                                        <div className=' d-flex justify-content-between' style={{ paddingBottom: "10px" }}>
                                            <div className="row">
                                                <label className="col-7 col-form-label" style={{ paddingRight: "0px", width: "54%" }} >Pay Slip for the month of</label>
                                                <div className="col-5 px-0 text-left " >
                                                    <input type="month" className="form-control inputs month" name='month' onChange={(event) => setMonth(event.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                        {/* table content */}
                                        <table className="table table-bordered table-sm" >
                                            <tbody className='ths'>
                                                <tr>
                                                    <th>Employee Name</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='name' onChange={(event) => setName(event.target.value)} /></td>
                                                    <th>Total Working Days</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='workingDays' onChange={(event) => setWorkingDays(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Employee ID</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='employeeId' onChange={(event) => setEmployeeId(event.target.value)} /></td>
                                                    <th>LOP Days</th>
                                                    <td><input type="text" className="border inputs border-0 form-control " name='lopDays' onChange={(event) => setLopDays(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Designation</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name=' designation' onChange={(event) => setDesignation(event.target.value)} /></td>
                                                    <th>Bank Name</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='bankName' onChange={(event) => setBankName(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Department</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='department' onChange={(event) => setDepartment(event.target.value)} /></td>
                                                    <th>Bank A/c No.</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='bankAccountNumber' onChange={(event) => setBankAccountNumber(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Date of Joining</th>
                                                    <td><input type="date" className="border border-0 form-control" name='dateOfJoining' onChange={(event) => setDateOfJoining(event.target.value)} /></td>
                                                    <th>PAN</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='panNumber' onChange={(event) => setPanNumber(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>UAN</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='uanNumber' onChange={(event) => setUanNumber(event.target.value)} /></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        <table className="table table-bordered table-sm" >
                                            <tbody className='ths'>
                                                <tr>
                                                    <th colSpan="2" className='text-center'><h3>Earnings</h3></th>
                                                    <th colSpan="2" className='text-center'><h3>Deductions</h3></th>
                                                </tr>
                                                <tr>
                                                    <th>Basic Salary</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='salary' onChange={(event) => setSalary(event.target.value)} /></td>
                                                    <th>PF</th>
                                                    <td>{pf}</td>
                                                </tr>
                                                <tr>
                                                    <th>House Rent Allowances</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='houseRentAllowance ' onChange={(event) => setHouse(event.target.value)} /></td>
                                                    <th>Income Tax</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='incomeTax' onChange={(event) => setIncome(event.target.value)} /></td>
                                                </tr>
                                                <tr>
                                                    <th>Conveyance Allowances</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name="conveyanceAllowance " onChange={(event) => setConvayance(event.target.value)} /></td>
                                                    <th>Professional Tax</th>
                                                    <td>{professionalTax}</td>
                                                </tr>
                                                <tr>
                                                    <th>Medical Allowances</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='medicalAllowance' onChange={(event) => setMedical(event.target.value)} /></td>
                                                    <th>LOP Amount</th>
                                                    <td>{Number(lopAmount).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th>Special Allowances</th>
                                                    <td><input type="text" className="border inputs border-0 form-control" name='specialAllowance' onChange={(event) => setSpecial(event.target.value)} /></td>
                                                    <td></td>
                                                    <td></td>
                                                </tr>
                                                <tr>
                                                    <th>Gross Salary</th>
                                                    <td>{grossSalary}</td>
                                                    <th>Total Deductions</th>
                                                    <td>{totalDeductions}</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="2" className='text-center'>Net Salary</th>
                                                    <td colSpan="2">{Number(netSalary).toFixed(2)}</td>
                                                </tr>
                                                <tr>
                                                    <th colSpan="2" className='text-center'>Amount in Words</th>
                                                    <td colSpan="2"><input type="text" className="border inputs border-0 form-control" value={amountinwords} /></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        {/* footer */}
                                        <div className='d-flex justify-content-between' style={{ paddingTop: "18px" }}>
                                            <p>This is a Computer generated slip and does not require signature.</p>
                                            <div className='d-flex'>
                                                <div>
                                                    <button onClick={onSubmitForm} type="button" className="btn btn-secondary btn-sm" style={{ height: "26px", paddingTop: "0px", marginRight: "10px" }}>Submit</button>
                                                </div>
                                                <div>
                                                    <button onClick={handlePrint} type="button" className="btn btn-secondary btn-sm" style={{ height: "26px", paddingTop: "0px" }}>Print</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div style={{ display: "none" }}>
                <div ref={componentRef} className="font spacing">
                    <div className="d-flex">
                        <div style={{ width: "50%" }}>
                            <img src={pic} alt="Logo" className='logo' />
                        </div>
                        <div>
                            <div className="vlp"></div>
                        </div>
                        <div>
                            <div id="DtHeading">Digital Triumph Private Limited</div>
                            <div style={{ fontSize: "15px" }}> AC 149,Plot No 4920,AC Block,<br />
                                Achu Towers, 3rd Floor, 2nd Avenue<br />
                                Anna Nagar, Chennai - 600040.</div>
                        </div>
                    </div>
                    <hr className="HorizontalLineDiv" style={{ marginTop: "0px" }} />
                    <div className='pb-3'>
                        <label>Pay Slip for the month of   </label>   {  new Date(month).toLocaleDateString('en-us', { year:"numeric", month:"long"})}
                    </div>
                    <table className="table table-bordered table-sm tables" >
                        <tbody className='ths'>
                            <tr>
                                <th>Employee Name</th>
                                <td>{name}</td>
                                <th>Total Working Days</th>
                                <td>{workingDays}</td>
                            </tr>
                            <tr>
                                <th>Employee ID</th>
                                <td>{employeeId}</td>
                                <th>LOP Days</th>
                                <td>{lopDays}</td>
                            </tr>
                            <tr>
                                <th>Designation</th>
                                <td>{designation}</td>
                                <th>Bank Name</th>
                                <td>{bankName}</td>
                            </tr>
                            <tr>
                                <th>Department</th>
                                <td>{department}</td>
                                <th>Bank A/c No.</th>
                                <td>{bankAccountNumber}</td>
                            </tr>
                            <tr>
                                <th>Date of Joining</th>
                                <td>{new Date(dateOfJoining).toLocaleDateString('en-GB')}</td>
                                <th>PAN</th>
                                <td>{panNumber}</td>
                            </tr>
                            <tr>
                                <th>UAN</th>
                                <td>{uanNumber}</td>
                                <td></td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>

                    <table className="table table-bordered table-sm tables" >
                        <tbody className='ths'>
                            <tr>
                                <th colSpan="2" className='text-center'><h3>Earnings</h3></th>
                                <th colSpan="2" className='text-center'><h3>Deductions</h3></th>
                            </tr>
                            <tr>
                                <th>Basic Salary</th>
                                <td>{salary}</td>
                                <th>PF</th>
                                <td>{pf}</td>
                            </tr>
                            <tr>
                                <th>House Rent Allowances</th>
                                <td>{houseRentAllowance}</td>
                                <th>Income Tax</th>
                                <td>{incomeTax}</td>
                            </tr>
                            <tr>
                                <th>Conveyance Allowances</th>
                                <td>{conveyanceAllowance}</td>
                                <th>Professional Tax</th>
                                <td>{professionalTax}</td>
                            </tr>
                            <tr>
                                <th>Medical Allowances</th>
                                <td>{medicalAllowance}</td>
                                <th>LOP Amount</th>
                                <td>{lopAmount}</td>
                            </tr>
                            <tr>
                                <th>Special Allowances</th>
                                <td>{specialAllowance}</td>
                                <td></td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Gross Salary</th>
                                <td>{grossSalary}</td>
                                <th>Total Deductions</th>
                                <td>{totalDeductions}</td>
                            </tr>
                            <tr>
                                <th colSpan="2" className='text-center ths'>Net Salary</th>
                                <td colSpan="2" className='tds'>{netSalary}</td>
                            </tr>
                            <tr>
                                <th colSpan="2" className='text-center ths'>Amount in Words</th>
                                <td colSpan="2" className='tds'>{amountinwords}</td>
                            </tr>
                        </tbody>
                    </table>
                    <p className='footer'>This is a Computer generated slip and does not require signature.</p>
                </div>
            </div>
        </div>
    )
}

export default Payslip