import React, { useState, useEffect } from "react";
import axios from 'axios';
import { NavLink } from "react-router-dom";
import { BsFillArrowLeftCircleFill } from 'react-icons/bs';


const PayslipDb = () => {
    const [employeeList, setEmployeeList] = useState([]);
    const [searchInput, setSearchInput] = useState("");
    const [filterResults, setFilterResults] = useState([]);

    const [searchDate, setSearchDate] = useState("");

    //"http://payslip.digitaltriumph.in/datas"
    const getEmployees = () => {
        axios.get("https://68.178.148.82:8080/paysliplist").then((response) => {
            setEmployeeList(response.data);
        })
    }

    const searchItems = (searchValue) => {
        setSearchInput(searchValue)
        if (searchInput !== "") {
            const filteredData = employeeList.filter((employeeList) => {
                // return Object.keys(invoiceList).some(key => {
                //     return invoiceList[key].toString().toLowerCase().includes(searchInput.toLowerCase());
                // })
                return Object.values(employeeList).join("").toLowerCase().includes(searchInput.toLowerCase())
            })
            setFilterResults(filteredData)
        }
        else {
            setFilterResults(employeeList)
        }
    }

const setMonth = (searchDate) => {
    setSearchDate(searchDate)
    if (searchDate !== ""){
      const filterDate = employeeList.filter((employeeList) => {
        return Object.values(employeeList).join("").includes(searchDate)
      })
      setFilterResults(filterDate)
}else{
    setFilterResults(employeeList)
}}

    useEffect(() => {
        getEmployees();
    }, []);

    return (
        // database values shown in table 
        <div>
            <div>
                <NavLink to='/dashboard'>
                    <button className='icons'><BsFillArrowLeftCircleFill size={25} /></button>
                </NavLink>
                <input icon='search'
                    placeholder='Type to Search Here...'
                    onChange={(e) => searchItems(e.target.value)} className="m-3 w-25"
                />
                <input type="month" className="searchDate" name='month' onChange={(e) => setMonth(e.target.value)} />

                <NavLink to="/payslip">
                    <button style={{backgroundColor:"#f66700",border:"none"}}>Create Payslip</button>
                </NavLink>
            </div>

            <table className="table-sm table-success table-striped">
                <thead>
                    <tr>
                        <th>S.No</th>
                        <th>Month</th>
                        <th>Name</th>
                        <th>Department</th>
                        <th>NetSalary</th>
                        <th>Items</th>
                    </tr>
                </thead>
                <tbody>
                    {searchDate.length || searchInput.length > 1 ? (
                        filterResults.map((employeeList) => {
                            return (
                                <tr>
                                    <td>{employeeList.id}</td>
                                    <td>{  new Date(employeeList.month).toLocaleDateString('en-us', {  month:"long",year:"numeric"})}</td>
                                    <td>{employeeList.name}</td>
                                    <td>{employeeList.department}</td>
                                    <td>{employeeList.netSalary}</td>
                                    <td><a style={{ textDecoration: "none" }} href={'/viewing/' + employeeList.id}>View</a></td>
                                </tr>
                            ) 
                        })
                    ) : (
                        employeeList.map((value, index) => {
                            return (
                                <tr>
                                    <td>{value.id}</td>
                                    <td>{new Date(value.month).toLocaleDateString('en-us', {  month:"long",year:"numeric"})}</td>
                                    <td>{value.name}</td>
                                    <td>{value.department}</td>
                                    <td>{value.netSalary}</td>
                                    <td><a style={{ textDecoration: "none" }} href={'/viewing/' + value.id}>View</a></td>
                                </tr>
                            )
                        }) 
                    )}
                </tbody>
            </table>
        </div>
    )
}

export default PayslipDb;